import $ from "jquery";

import { config, dom, library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

import {
  faClock,
} from "@fortawesome/free-regular-svg-icons";

// 必要なアイコンをライブラリに追加
library.add(faChevronLeft, faChevronRight, faChevronUp, faClock);

// アイコンを<i>タグから<svg>タグに変換
dom.watch();

// 100vwの調整
function setVw() {
  // --vwをセットする関数
  let vw = $(window).width() / 100 + "px";
  // ブラウザ幅/100を取得し変数vwに格納
  $(":root").css("--vw", vw);
  // :rootのカスタムプロパティ--vwにvwを代入させる。これで、スクロールバーの幅を除いた画面幅/100が--vwになる
}

$(window).on("load resize", function () {
  setVw();
});
// 画面を、読み込んだ時・サイズを変えた時  →  関数setVwが動作する

// 初期ロード時にも関数を実行
setVw();

// スムーススクロール
$(function () {
  $('a[href^="#"]').click(function (e) {
    e.preventDefault(); // デフォルトのアンカー動作を停止

    var speed = 2000;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;

    $("html, body").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
  });
});

// ページトップボタン 1000pxをスクロールしたところで表示
$(function () {
  var pagetop = $(".js-pageTop");
  // ボタン非表示
  pagetop.hide();

  $(window).scroll(function () {
    if ($(this).scrollTop() > 1000) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
});
